@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Scto Grotesk";
  src: url("../assets/fonts/Scto\ Grotesk\ A\ Regular.eot");
  src: url("../assets/fonts/Scto\ Grotesk\ A\ Regular.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "Scto Grotesk";
  src: url("../assets/fonts/Scto\ Grotesk\ A\ Light.eot");
  src: url("../assets/fonts/Scto\ Grotesk\ A\ Light.woff") format("woff");
  font-weight: light;
}

@font-face {
  font-family: "Scto Grotesk";
  src: url("../assets/fonts/Scto\ Grotesk\ A\ Medium.eot");
  src: url("../assets/fonts/Scto\ Grotesk\ A\ Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Scto Grotesk";
  src: url("../assets/fonts/Scto\ Grotesk\ A\ Bold.eot");
  src: url("../assets/fonts/Scto\ Grotesk\ A\ Bold.woff") format("woff");
  font-weight: bold;
}

body {
  /* margin: 0; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  @apply text-[1.125rem] -tracking-[0.02em] leading-[1.36em];
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, .h1 {
  @apply text-[3rem] -tracking-[0.04em] leading-[0.96em] font-medium;
}

h2, .h2 {
  @apply text-[2.5rem] -tracking-[0.03em] leading-[0.96em] font-medium;
} 

h3, .h3 {
  @apply text-[2rem] -tracking-[0.02em] leading-[1.2em] font-medium;
} 

h4, .h4 {
  @apply text-[1.5rem] -tracking-[0.02em] leading-[1.2em] font-medium;
} 

h5, .h5,
h6, .h6 {
  @apply text-[1.125rem] -tracking-[0.02em] leading-[1.2em] font-medium;
} 

p {
  @apply text-[1.125rem] -tracking-[0.02em] leading-[1.36em] mb-4;
}

p.small {
  @apply text-[1rem] -tracking-[0.02em] leading-[1.4em];
}

p.smaller {
  @apply text-[0.875rem] -tracking-[0.02em] leading-[1.4em];
}

a {
  @apply text-op-blue underline font-medium transition-all;
}

strong {
  @apply font-medium;
}

@keyframes front-logo {
  0%, 20% {
    opacity: 0;
  }
  5%, 15% {
    opacity: 1
  }
}

.op-btn {
  @apply px-11 py-3 rounded-xl font-medium shadow-lg text-white bg-op-blue text-xl transition-all hover:opacity-90 inline-block text-center no-underline select-none;
}

a.disabled,
.op-btn.disabled,
.op-btn[disabled] {
  @apply pointer-events-none bg-grey-6 !cursor-not-allowed;
}

input[type="radio"][name="signer"]:checked + label {
  @apply bg-op-blue;
}

.op-radio:before {
  content: '';
  @apply w-4 h-4 rounded-full border border-op-darkest-blue flex justify-center items-center absolute;
}

.op-radio:after {
  content: '';
  /* @apply w-4 h-4 rounded-full border border-op-darkest-blue mx-4 flex justify-center items-center absolute; */
  @apply w-2.5 h-2.5 rounded-full bg-op-darkest-blue absolute left-[3px] hidden;
}

.op-radio.checked:after {
  @apply inline-block;
}

li {
  @apply mb-1;
}

.bank-list,
.account-list {
  max-height: calc(100svh - 10rem);
}
@media (max-width: 640px) {
  .bank-list,
  .account-list {
    max-height: calc(100svh - 7.5rem);
  }
}

@media (max-height: 750px) {
  .bank-list {
    @apply max-h-fit;
  }
}

@media (max-height: 550px) {
  .account-list {
    @apply max-h-fit;
  }
}